import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"

const Page = ({data}) => {
  const bredcrumb = "";
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  const html = block(lang)
  return(
  <Layout bredcrumb={bredcrumb} lang={lang}>
    <Seo title="404 Not Found" lang={lang} />
    <ContentLayout lang={lang}>

      <h1>404 Not Found</h1>
      {html}
    </ContentLayout>
  </Layout>
  )
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

function block(language) {
  if(language==='en'){
    return (
      <>
        <p>The page you have requested cannot be found. It may have been moved or taken off the site. Please check the spelling of the web address you entered and ensure it is correct.</p>
        <p>We apologize for any inconvenience.</p>
      </>
    )
  }else{
    
    return (
      <>
        <p>お探しのページが見つかりませんでした。</p>
        <p>誠に恐れ入りますが、お客様がアクセスしようとしたページまたはファイルが見つかりませんでした。</p>
        <p>お探しのページは、削除または名前が変更された、もしくは一時的に使用できなくなっている可能性がございます。</p>
      </>
    )
  }
}